<div class="flex justify-content-between mt-6 mb-3 c-header">
  <span class="font-bold white-space-nowrap m-0">Conversation #: {{ conversation.id }}</span>
  <p-button icon="pi pi-bell" [text]="true" label="{{ conversation.status }}" severity="success" class="mr-2" />
  <strong class="ml-4 mr-2">Created Time: </strong>
  {{
    conversation.created_time
      | date
        : "MMM dd, yyyy hh:mm:ss
    a"
  }}
  <strong class="ml-2 mr-2">Modified Time: </strong>
  {{
    conversation.modified_time
      | date
        : "MMM dd, yyyy hh:mm:ss
    a"
  }}
  <p-button [text]="true" icon="pi pi-times" severity="contrast" (click)="ref.close()" />
</div>
<p-card [style]="{ 'background-color': 'white' }">
  <div class="card-conv">
    <p-table [value]="conversation.comments" [paginator]="false" [scrollable]="true" scrollHeight="flex" styleClass="p-datatable-striped">
      <ng-template pTemplate="body" let-i>
        <tr class="data-row-conv">
          <p-panel header="{{ i.created_time | date : 'MMM dd, yyyy hh:mm:ss a' }} - {{ i.created_by }}">
            <div [innerHTML]="i.comment" class="comment-details"></div>
          </p-panel>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
<p-toolbar>
  <div class="p-toolbar-group-start editor-button">
    <div class="mr-2">
      <p-editor [(ngModel)]="commentEditor" [style]="{ height: '50px' }" />
    </div>
    <div>
      <p-button icon="pi pi-send" label="Add Comment" severity="success" class="p-button-success" (click)="sendNewConversationComment()" />
    </div>
  </div>
</p-toolbar>
