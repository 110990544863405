import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, OnInit, Output } from '@angular/core';
import { SharePrimeNGModule } from '../share-primeng.module';
import { TrackingService } from '../services/tracking.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { ShipmentData } from '../models/tracking.model';
import { SettingService } from '../services/setting.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-create-label-popup',
  standalone: true,
  imports: [SharePrimeNGModule],
  templateUrl: './create-label-popup.component.html',
  styleUrl: './create-label-popup.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateLabelPopupComponent implements OnInit {
  createLabelRequests: any[] = [
    //   {
    //     orderId: 1610,
    //     status: 'Requesting label creation',
    //     message: '',
    //     shipmentData: {
    //       addressTo: {
    //         name: 'Ky Nguyen',
    //         company: 'Personal',
    //         street1: '12415 Exbury Ct',
    //         city: 'Tomball',
    //         state: 'TX',
    //         zip: '77377',
    //         country: 'US',
    //         phone: '+1 512-456-7890',
    //         email: 'recipient@example.com',
    //       },
    //       parcels: [
    //         {
    //           length: '10',
    //           width: '5',
    //           height: '8',
    //           distanceUnit: 'in',
    //           weight: '2',
    //           massUnit: 'lb',
    //         },
    //       ],
    //       async: false,
    //     } as ShipmentData,
    //   },
    //   {
    //     orderId: 1611,
    //     status: 'Requesting label creation',
    //     message: '',
    //     trackingNumber: '',
    //     labelUrl: '',
    //     shipmentData: {
    //       addressTo: {
    //         name: "Ky Nguyen",
    //         company: "Personal",
    //         street1: "18402 Siberia Bay Cir",
    //         city: "Cypress",
    //         state: "TX",
    //         zip: "77429",
    //         country: "US",
    //         phone: "+1 512-456-7890",
    //         email: "recipient@example.com"
    //       },
    //       parcels: [
    //         {
    //           length: '10',
    //           width: '5',
    //           height: '8',
    //           distanceUnit: 'in',
    //           weight: '1',
    //           massUnit: 'lb',
    //         },
    //       ],
    //       async: false,
    //     } as ShipmentData,
    //   },
  ];

  selectedOrders: any[] = [];

  constructor(private trackingService: TrackingService, private orderService: OrderService, private settingService: SettingService) { }

  ngOnInit(): void {
    this.orderService.getOrdersWithoutLabel().subscribe(({ data }: any) => {
      this.createLabelRequests = data.map((order: any) => {
        //'first_name', 'last_name', 'street1', 'city', 'state', 'zip_code', 'country', 'phone', 'email'
        const missingFields = ['first_name', 'last_name', 'street1', 'city', 'state', 'zip_code', 'country'].filter(key => !order[key]);
        const parcel = order.order_variants.reduce((acc: any, orderVariant: any) => {
          const qty = orderVariant.quantity;
          let { weight, length, width, height } = orderVariant.variant;

          if (length < acc.length) {
            length = acc.length
          }

          if (width < acc.width) {
            width = acc.width
          }

          return { length, width, height: height * qty + (acc.height || 0), weight: weight * qty + (acc.weight || 0), distanceUnit: 'in', massUnit: 'lb', };
        }, {});

        ['length', 'width', 'height', 'weight'].forEach(key => {
          parcel[key] += '';
        });

        return {
          orderId: order.id,
          status: 'queued',
          message: missingFields.length ? `Missing shipping info: ${missingFields.join(', ')}` : '',
          disabled: !!missingFields.length,
          shipmentData: {
            orderId: order.id,
            addressTo: {
              name: `${order.first_name} ${order.last_name}`,
              //company: 'Personal',
              street1: `${order.street1} ${order.street2 || ''}`,
              city: order.city,
              state: order.state,
              zip: order.zip_code,
              country: order.country,
            },
            parcels: [
              parcel
            ],
            async: false,
          } as ShipmentData
        };
      }).sort((a: any, b: any) => b.orderId - a.orderId);
    });
  }

  onHeaderCheckboxToggle() {
    this.selectedOrders = this.selectedOrders.filter((order: any) => !order.disabled || order.status !== 'queued');
  }

  // Function to call API for each createLabelRequest using trackingService.CreateShippoLabel
  /*
    Sample HTTP response for each API call:
    {
      createdBy: {
        firstName: 'Henry',
        lastName: 'Le',
        username: 'henry.le134@gmail.com'
      },
      labelUrl: 'https://deliver.goshippo.com/6587421b2e854e99aa39ad863520df0e.pdf?Expires=1764491455&Signature=Bu4a1TEjSfi~wExZkFoP7Dio2JcUV6L0MZQTh8dAmYWPdGglgFatIgQREPpglNnBriSiO9sVxT~0WPJAQ8HTck7UKkiaNDwO0Tmeqd4f3KUmrczhugfKuc5dv8a3b~5p-N9wRrMaAWzkSAAMHkm-QpwWK6Akae6WPsx1BON3UnkcFjWhuaOoRFHx1mXOzRxh81WoNODcZzywhdvV0HWynhI0cnFUPq9wXyrkp5MJBNsAw3x3vEwOAuqFa1Zx982yRgoKXbxgqWW~-6zRP~~S~v3bRk6d960odBjLdNEBIXoXlCyZPMHjeiu8zjHXOHBCp1WkddCn64JqQkRa80JNSw__&Key-Pair-Id=APKAJRICFXQ2S4YUQRSQ',
      messages: [],
      metadata: '',
      objectCreated: 2024-11-30T08:30:55.062Z,
      objectId: '6587421b2e854e99aa39ad863520df0e',
      objectOwner: 'henry.le134@gmail.com',
      objectState: 'VALID',
      objectUpdated: 2024-11-30T08:30:56.695Z,
      parcel: '77c3088156a6481cb7c45ed7b77ca7d9',
      rate: '754315544d9c4457852e413294f11054',
      status: 'SUCCESS',
      test: false,
      trackingNumber: '92346903470167300058980549',
      trackingStatus: 'PRE_TRANSIT',
      trackingUrlProvider: 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=92346903470167300058980549'
    }

   */

  createLabelsForRequests() {
    const requests = this.selectedOrders.filter((order: any) => order.status === 'queued');
    this.selectedOrders.forEach((order: any) => {
      order.status = 'Requesting label creation';
    });
    this.settingService.getSetting('SHIPPING_ADDRESS_FROM').subscribe((shippingAddressFrom: any) => {
      forkJoin(
        requests.map(({ disabled, ...request }: any, index: number) => {
          // Set the shippingAddressFrom to request.shipmentData.addressFrom
          request.shipmentData.addressFrom = JSON.parse(shippingAddressFrom[0]);

          return this.trackingService.createShippoLabel(request.shipmentData as ShipmentData).pipe(
            catchError((error) => {
              const messages = [{
                text: `Failed: ${error.messages || error.error}`
              }];
              return of({ status: 'ERROR', messages }); // Handle errors gracefully by returning null
            }),
            tap((response) => {
              const currentRequest = this.createLabelRequests.find((r: any) => r.orderId === request.orderId);
              currentRequest.status = response.status;
              currentRequest.labelUrl = response.labelUrl;
              currentRequest.trackingNumber = response.trackingNumber;
              currentRequest.disabled = !!response.trackingNumber;

              if (response.status === 'ERROR') {
                currentRequest.message = response.messages[0]?.text;
                currentRequest.status = 'queued';
              }
            })
          );
        })
      ).pipe(
        tap(() => {
          this.selectedOrders = [];
        })
      ).subscribe({
        next: (responses) => {

        },
        error: (error) => {
          console.error('Unexpected error:', error);
        },
      });
    });
  }

}
