import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../share-primeng.module';
import { FileUploadModule } from 'primeng/fileupload';
import { MyShopService } from '../services/myshop.service';
import { query } from 'express';
import { AuthenticationService } from '../services/authentication.service';
import { ConversationService } from '../services/conversation.service';
import { OrderCollectionQuery } from '../models/mshop.model';

@Component({
  selector: 'app-my-shops',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule, FileUploadModule],
  providers: [MessageService],
  templateUrl: './my-shops.component.html',
  styleUrl: './my-shops.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class MyShopsComponent {
  activeIndex: number = 0;
  orderCollections: any = [];
  totalCollections = 0;
  selectedFiles: File[] = []; // Array to store selected files

  defaultPageLimit: number = 30;

  constructor(private authenticationService: AuthenticationService, private messageService: MessageService, private myShopService: MyShopService) { }

  ngOnInit(): void {
    this.getMyOrderCollections();
  }

  onFilesSelected(event: any): void { }

  onUploadFiles(event: any): void {
    this.selectedFiles = event.files;
    if (this.selectedFiles.length === 0) {
      console.warn('No files selected');
      return;
    }

    const filePromises = this.selectedFiles.map((file) => this.convertToBase64(file));

    // Wait for all files to be converted to Base64
    Promise.all(filePromises)
      .then((base64Files) => {
        const payload = { files: base64Files }; // Send as an array of encoded files
        this.myShopService.uploadEncodedCSVFiles(payload).subscribe({
          next: (response) => {
            console.log('Files uploaded successfully:', response);
            this.selectedFiles = []; // Clear selected files after upload

            this.getMyOrderCollections();
          },
          error: (error) => {
            console.error('Error uploading files:', error);
          },
        });
      })
      .catch((error) => console.error('Error encoding files:', error));
  }

  // Helper function to convert file to Base64
  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  getMyOrderCollections(
    query: OrderCollectionQuery = {
      offset: 0,
      limit: this.defaultPageLimit,
    }
  ) {
    this.myShopService.getMyOrderCollections(query).subscribe({
      next: (response) => {
        this.orderCollections = response.data;
        this.totalCollections = response.total;
      },
      error: (error) => {
        this.sendError('Load order collections!', `Unable to perform action: ${error}`);
      },
    });
  }

  onPageChange(event: any) {
    this.getMyOrderCollections({ offset: event.page * event.rows, limit: event.rows });
  }

  processIncompleteCollections() {
    this.myShopService.processIncompleteCollections().subscribe({
      next: (response) => {
        this.getMyOrderCollections();
      },
      error: (error) => {
        this.sendError('Process Incomplete Order Collections!', `Unable to perform action: ${error}`);
      },
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }

  checkConnectionTikTok() {
    this.myShopService.checkConnectionTikTok().subscribe({
      next: () => { },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Unable to perform the action: ${err}`,
        });
      },
    });
  }

  viewImportDetailDialog = false;
  selectedDetails: any = null;
  showImportDetailDialog(collection: any) {
    try {
      this.selectedDetails = JSON.parse(collection.comment);
    } catch (e) {
      this.selectedDetails = null;
    }
    this.viewImportDetailDialog = true;
  }
}
