<div class="card flex justify-content-center">
    <div class="grid">
        @if(timelines && timelines.length) {
        <div class="col-2 lg:col-2 md:col-12 overflow-auto" [style.height.px]="mainForm.offsetHeight">
            <div class="col-12">
                <app-timeline [timelines]="timelines"></app-timeline>
            </div>
        </div>
        }
        <div class="col" #mainForm>
            <p-accordion [activeIndex]="[0, 1]" [multiple]="true" [formGroup]="formGroup">
                @for(config of sectionConfigs; track config.section) {
                <p-accordionTab [header]="config.section">
                    <div class="grid">
                        @if(config.common) {
                        <div class="{{!config.common.occupy ? 'col' : 'col-' + config.common.occupy}}">
                            @if(config.common.type === TYPES.TOOLBAR) {
                            <app-toolbar [configs]="config.common.configurations"></app-toolbar>
                            }
                        </div>
                        }
                        @if(!config[config.key]) {
                        <ng-container [ngTemplateOutlet]="fieldGroup"
                            [ngTemplateOutletContext]="{ config, form: formGroup }"></ng-container>
                        } @else {
                        <ng-container [formArrayName]="config.key">
                            @for(item of listSectionConfigSignal!()[config.key]; track item; let idx = $index; let last
                            =
                            $last)
                            {
                            <div class="grid col-12"
                                [ngClass]="!getFormGroupValidity(config.key, idx) ? 'border-red-600 border-1' : ''">
                                <div class="col">
                                    <div class="grid" #leftContainer>
                                        @if(config.eachItem) {
                                        <div
                                            class="{{!config.eachItem.occupy ? 'col' : 'col-' + config.eachItem.occupy}}">
                                            @if(config.eachItem.type === TYPES.CHECKBOX){
                                            <p-checkbox class="mr-1" [binary]="true" inputId="binary"
                                                [(ngModel)]="checkboxState[idx]"
                                                [ngModelOptions]="{standalone: true}" />Item
                                            #{{idx + 1}}
                                            }
                                        </div>
                                        }

                                        <ng-container [formGroupName]="idx">
                                            <ng-container [ngTemplateOutlet]="fieldGroup"
                                                [ngTemplateOutletContext]="{ config: listSectionConfigSignal!(), form: getFormGroupFromFormArray(config.key, idx), last: last || true }"></ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="flex">
                                        @for(image of item.images; track image) {
                                        <div class="col">
                                            <div class="mb-1 text-lg">{{image.title}}</div>
                                            <app-image [src]="image.byPassCORSrc" [text]="image.title"
                                                [context]="{disabledPreview: true}" [width]="200" [height]="200" />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <!-- @if (config.hasImages) {
                                <div class="md:col-4 sm:col-12 xl:col-3">
                                    <p-galleria [value]="item.images"
                                        [containerStyle]="{ 'max-width': '100%', 'max-height': '100%' }"
                                        [showItemNavigators]="true" [showItemNavigators]="true" [showThumbnails]="false"
                                        [numVisible]="6">
                                        <ng-template pTemplate="item" let-item>
                                            <img [src]="item.byPassCORSrc"
                                                [style.height.px]="leftContainer.offsetHeight"
                                                style="width: 100%; display: block;" />
                                        </ng-template>

                                        <ng-template pTemplate="caption" let-item>
                                            <h4 class="m-1" style="color: #ffffff;">
                                                {{ item.title }}
                                            </h4>
                                        </ng-template>
                                    </p-galleria>
                                </div>
                                } -->
                            </div>
                            }
                        </ng-container>
                        }
                    </div>
                </p-accordionTab>
                }
                <app-toolbar [configs]="toolbarForSave"></app-toolbar>
            </p-accordion>
        </div>
    </div>
</div>

<ng-template #fieldGroup let-config="config" let-form="form" let-last="last">
    <div class="col surface-border" [class]="{'border-bottom-1': last}" [formGroup]="form">
        <div class="grid">
            <div
                class="{{!config.occupy?.leftSide ? 'md:col' : 'md:col-' + config.occupy.leftSide}} sm:col-12 border-x-1 surface-border">
                <div class="grid">
                    @for(field of config.leftSide; track field.label) {
                    <div class="{{!field.occupy ? 'col' : 'col-' + field.occupy}} pt-4">
                        <p-floatLabel>
                            @if(field.type === TYPES.INPUT) {
                            <input class="w-full" pInputText id="{{field.key}}" placeholder="{{field.placeholder}}"
                                [formControlName]="field.key" [readonly]="field.readonly" />
                            }
                            @if(field.type === TYPES.DROPDOWN) {
                            <p-dropdown styleClass="w-full" [options]="field.options" [formControlName]="field.key"
                                [optionLabel]="field.key" optionValue="value" inputId="{{field.key}}" [showClear]="true"
                                [filter]="true" appendTo="body" [filterFields]="[field.filterBy, field.key]" />
                            }
                            @if(field.type === TYPES.INPUTNUMBER) {
                            <p-inputNumber inputStyleClass="w-full" placeholder="{{field.placeholder}}"
                                [formControlName]="field.key" />
                            }
                            @if(field.type === TYPES.INPUTSWITCH) {
                            <p-inputSwitch [formControlName]="field.key" [readonly]="field.readonly" [trueValue]="false"
                                [falseValue]="true" (onChange)="inputSwitch.onBlur()" #inputSwitch />
                            <input class="hidden" pInputText id="{{field.key}}" placeholder="{{field.placeholder}}"
                                value="hidden" [readonly]="field.readonly" />
                            }
                            <label for="{{field.key}}">{{field.label}}</label>
                        </p-floatLabel>
                    </div>
                    }
                </div>
            </div>
            <div
                class="{{!config.occupy?.rightSide ? 'md:col' : 'md:col-' + config.occupy.rightSide}} sm:col-12 border-x-1 surface-border">
                <div class="grid">
                    @for(field of config.rightSide; track field.label; let index = $index;) {
                    <div class="{{!field.occupy ? 'col' : 'col-' + field.occupy}} pt-4">
                        @if(config.hasImages) {
                        <p-inputGroup class="w-full">
                            <p-floatLabel class="w-full">
                                <!-- (blur)="op?.hide()" (focus)="url.value && op?.show($event)" -->
                                <input class="w-full" pInputText id="{{field.key}}" placeholder="{{field.placeholder}}"
                                    [formControlName]="field.key" [readonly]="field.readonly" #url />
                                <label for="{{field.key}}">{{field.label}}</label>
                            </p-floatLabel>
                            <button *ngIf="url.value" type="button" pButton icon="pi pi-image"
                                class="p-button-info"></button>
                            <!-- (click)="op.toggle($event)" -->
                            <!-- <p-overlayPanel #op>
                                <div class="text-center text-lg font-bold">{{field.label}}</div>
                                <app-image [src]="getBypassCorURL(url.value)" [text]="field.label"
                                    [context]="{retainUrl: true, disabledPreview: true}" [width]="500" [height]="400" />
                            </p-overlayPanel> -->
                        </p-inputGroup>
                        } @else {
                        <p-floatLabel>
                            @if(field.type === TYPES.INPUT) {
                            <input class="w-full" pInputText id="{{field.key}}" placeholder="{{field.placeholder}}"
                                [formControlName]="field.key" [readonly]="field.readonly" />
                            }
                            @if(field.type === TYPES.DROPDOWN) {
                            <p-dropdown styleClass="w-full" [options]="field.options" [formControlName]="field.key"
                                [optionLabel]="field.key" optionValue="value" inputId="{{field.key}}" [showClear]="true"
                                [filter]="true" appendTo="body" [filterFields]="[field.filterBy, field.key]" />
                            }
                            @if(field.type === TYPES.INPUTNUMBER) {
                            <p-inputNumber inputStyleClass="w-full" placeholder="{{field.placeholder}}"
                                [formControlName]="field.key" />
                            }
                            @if(field.type === TYPES.INPUTSWITCH) {
                            <p-inputSwitch [formControlName]="field.key" [readonly]="field.readonly" [trueValue]="false"
                                [falseValue]="true" (onChange)="inputSwitch.onBlur()" #inputSwitch />
                            <input class="hidden" pInputText id="{{field.key}}" placeholder="{{field.placeholder}}"
                                value="hidden" [readonly]="field.readonly" />
                            }
                            <label for="{{field.key}}">{{field.label}}</label>
                        </p-floatLabel>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-confirmDialog />