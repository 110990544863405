import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef } from '@angular/core';
import { WalletToolbarComponent } from '../../shared-ui/wallet-toolbar/wallet-toolbar.component';
import { WalletService } from '../../services/wallet.service';
import { TransactionStatus, TransactionType, WalletTransaction } from '../../models/wallet.model';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
import { Setting } from '../../models/setting.model';
import { AuthenticationService } from '../../services/authentication.service';
import { MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { finalize } from 'rxjs/operators';
import { StripeService } from '../../services/stripe.service';
import { StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

interface Filter {
  userName: string;
  maxDate: Date;
}

@Component({
  selector: 'app-my-wallet',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule, WalletToolbarComponent],
  providers: [MessageService],
  templateUrl: './my-wallet.component.html',
  styleUrl: './my-wallet.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyWalletComponent {
  settings: Setting[] = [];
  transactions: WalletTransaction[] = [];
  totalTransactions: number = 0;
  myBalance: number = 0;
  filters: Filter = {
    userName: '',
    maxDate: new Date(),
  };
  currentPage: any = {
    page: 0,
    rows: 20,
  };
  displayDepositDialog: boolean = false;
  displayPayByCard: boolean = false;
  depositForm!: FormGroup;
  newTransaction: WalletTransaction = {
    id: 0,
    type: TransactionType.FUND,
    user_name: '',
    transfer_type: '',
    balance: 0,
    amount: 0,
    change_by: '',
    status: TransactionStatus.REQUESTED,
    note: '',
    created_time: new Date(),
    modified_time: new Date(),
  };
  transferTypes: string[] = [];
  loginUserName: any;
  isLoading = false;

  private elements!: StripeElements;
  private cardElement!: StripeCardElement;

  constructor(
    private walletService: WalletService,
    private settingService: SettingService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private stripeService: StripeService,
    private changeDetector: ChangeDetectorRef
  ) {
    authenticationService.user.subscribe((user) => {
      this.loginUserName = user;
    });
    this.loginUserName = this.authenticationService.userSession.userName;
  }

  async ngOnInit(): Promise<void> {
    this.loadSettings();
    this.depositForm = this.fb.group({
      amount: ['', Validators.required],
      account: ['', Validators.required],
    });
    this.getTransactions();
    this.getMyBalance();
  }

  getTransactions(offset: number = 0, limit: number = 20) {
    this.isLoading = true;
    const userName = this.loginUserName;

    this.walletService
      .findTransactions(offset, limit, userName, '', '')
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.transactions = response.data;
          this.totalTransactions = response.total;
        },
        error: (error) => {
          this.sendError('Load wallet transactions!', `Unable to perform action: ${error}`);
        },
      });
  }

  getMyBalance() {
    this.walletService.findUserBalance(this.loginUserName).subscribe({
      next: (response) => {
        this.myBalance = response.balance;
      },
      error: (error) => {
        this.sendError('Load my balance!', `Unable to perform action: ${error}`);
      },
    });
  }

  cancelTransaction(t: WalletTransaction): void {
    t.status = TransactionStatus.CANCELLED;
    t.modified_time = new Date();
    this.walletService.updateTransaction(t).subscribe({
      next: () => {
        this.sendInfo('Update transaction!', `Transaction #${t.id} cancelled successfully`);
        this.reloadTransactions();
      },
      error: (error) => {
        this.sendError('Update transaction!', `Unable to perform action: ${error}`);
      },
    });
  }

  onPageChange(event: any) {
    this.currentPage = event;
    this.getTransactions(event.page * event.rows, event.rows);
  }

  reloadTransactions() {
    // Reload transactions and keep current page data
    if (this.currentPage) {
      this.getTransactions(this.currentPage.page * this.currentPage.rows, this.currentPage.rows);
    } else {
      this.getTransactions();
    }

    this.getMyBalance();
  }

  async onTransferTypeChange(selectedValue: string) {
    if (['credit card', 'card'].includes(selectedValue?.toLowerCase())) {
      this.displayPayByCard = true;
      await this.loadStripe();
    } else {
      this.displayPayByCard = false;
    }
  }

  async onSubmitDeposit(): Promise<void> {
    this.newTransaction.user_name = this.loginUserName;
    this.newTransaction.change_by = this.loginUserName;

    // Check if transfer_type is provided
    if (!this.newTransaction.transfer_type) {
      this.sendError('Validation Error', 'Transfer type is required.');
      return;
    }

    // Pay by Credit Card
    if (['credit card', 'card'].includes(this.newTransaction.transfer_type?.toLowerCase())) {
      const { paymentResult, paymentIntentId } = await this.proceedCreditPayment(this.newTransaction.amount);
      if (!paymentResult) {
        return;
      }

      this.newTransaction.status = TransactionStatus.APPROVED;
      this.newTransaction.note = paymentIntentId;
    }

    this.walletService.createTransaction(this.newTransaction).subscribe({
      next: () => {
        this.reloadTransactions();
      },
      error: (error) => {
        this.sendError('Create transaction!', `Unable to perform action: ${error}`);
      },
      complete: () => {
        this.displayDepositDialog = false;
      },
    });
  }

  loadSettings() {
    this.settingService.findAllSettings().subscribe({
      next: (response) => {
        this.settings = response;
        const transferTypes = this.settings.find((s) => s.key === 'TRANSACTION_TRANSFER_TYPE')?.value;
        if (transferTypes) {
          this.transferTypes = transferTypes.split(';');
        }
      },
      error: (error) => {
        this.sendError('Load setting!', `Unable to perform action: ${error}`);
      },
      complete: () => {},
    });
  }

  async loadStripe() {
    this.changeDetector.detectChanges(); // Manually trigger change detection

    this.stripeService.loadStripe().subscribe(() => {
      const stripe = this.stripeService['stripe'];
      if (stripe) {
        this.elements = stripe.elements();
        this.cardElement = this.elements.create('card');

        const cardElementContainer = document.getElementById('card-element');
        if (cardElementContainer) {
          cardElementContainer.innerHTML = ''; // Clear any child elements
        }

        this.cardElement.mount('#card-element');

        // Add event listener for handling card input errors
        this.cardElement.on('change', (event) => {
          const errorContainer = document.getElementById('card-errors');
          if (errorContainer) {
            if (event.error) {
              // Display the error message if there's an issue
              errorContainer.textContent = event.error.message;
            } else {
              // Clear any previous error message if there are no errors
              errorContainer.textContent = '';
            }
          }
        });
      } else {
        console.error('Stripe instance is not initialized');
      }
    });
  }

  async showDepositMoneyDialog() {
    this.displayDepositDialog = true;
    await this.loadStripe();
  }

  async proceedCreditPayment(amount: number) {
    let paymentResult = false;
    let paymentIntentId: any;

    try {
      const paymentIntent = await firstValueFrom(this.stripeService.createPaymentIntent(amount * 100, 'usd'));

      const clientSecret = paymentIntent.client_secret;

      const result = await this.stripeService.confirmCardPayment(clientSecret, this.cardElement);

      if (result.error) {
        console.error(result.error.message);
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        this.sendInfo('Credit Payment', `Payment successful!`);
        paymentResult = true;
        paymentIntentId = result.paymentIntent.id;
      }
    } catch (error) {
      this.sendError('Credit Payment', `Payment failed: ${error}`);
    }

    return { paymentResult, paymentIntentId };
  }

  copyToClipboard(note: string) {
    if (note) {
      navigator.clipboard
        .writeText(note)
        .then(() => {})
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
