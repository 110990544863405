export enum TYPES {
    INPUT,
    DROPDOWN,
    RANGEDATE,
    BUTTONGROUP,
    COLSPAN,
    TOOLBAR,
    CHECKBOX,
    INPUTNUMBER,
    DATERANGE,
    INPUTSWITCH,
};

interface IInputFilterConfigModel {
    type: TYPES.INPUT,
    label: string,
    key: string,
};

interface IDateRangeFilterConfigModel {
    type: TYPES.DATERANGE,
    label: string,
    key: string,
};

type Option<K extends string> = { [P in K]: string | number } & { value: any, count?: number };

interface IDropdownFilterConfigModel<K extends string> {
    type: TYPES.DROPDOWN,
    label: string,
    key: string,
    optionLabel: K,
    hasFilter?: boolean,
    options: Option<K>[],
};

interface IButtonGroupFilterConfigModel {
    type: TYPES.BUTTONGROUP,
    buttons: { label: string, severity: string, action: (arg: any) => any }[]
};

export type FilterConfigModel<K extends string> = {
    occupy?: number
} & (IInputFilterConfigModel | IDropdownFilterConfigModel<K> | IButtonGroupFilterConfigModel | IDateRangeFilterConfigModel);

export interface ViewConfigModel {
    views: Option<'name'>[],
    action: (arg: any) => any,
};

export interface ColumnModel {
    sortable?: boolean;
    field: string;
    header: string;
    width: string;
    template: 'idColTemplate' | 'commonTemplate' | 'designItemTemplate' | 'trackingTemplate' | 'currencyTemplate' | 'simpleCurrencyTemplate' | 'dateTemplate' | 'durationTemplate' | 'actionTemplate' | 'statusTemplate' | 'booleanTemplate';
};