import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { WalletTransaction, WalletUserBalance } from '../models/wallet.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private apiUrl = `${environment.apiUrl}/wallet`;

  constructor(private http: HttpClient) {}

  countTransactions(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/countTransactions`);
  }

  findTransactions(
    offset: number,
    limit: number,
    userName: string,
    startDate: string,
    endDate: string,
    type: string = ''
  ): Observable<{ total: number; data: WalletTransaction[] }> {
    const params = {
      offset: offset,
      limit: limit,
      userName: userName,
      startDate: startDate,
      endDate: endDate,
      type: type,
    };
    return this.http.get<{ total: number; data: WalletTransaction[] }>(`${this.apiUrl}/findTransactions`, { params });
  }

  updateTransaction(t: WalletTransaction): Observable<WalletTransaction> {
    return this.http.post<WalletTransaction>(`${this.apiUrl}/updateTransaction`, t);
  }

  createTransaction(t: WalletTransaction): Observable<WalletTransaction> {
    return this.http.post<WalletTransaction>(`${this.apiUrl}/createTransaction`, t);
  }

  findAllUserBalance(): Observable<WalletUserBalance[]> {
    return this.http.get<WalletUserBalance[]>(`${this.apiUrl}/findAllUserBalance`);
  }

  findUserBalance(userName: string): Observable<WalletUserBalance> {
    const params = {
      userName: userName,
    };
    return this.http.get<WalletUserBalance>(`${this.apiUrl}/find-user-balance`, { params });
  }
}
