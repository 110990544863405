import { Component, CUSTOM_ELEMENTS_SCHEMA, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { merge, of } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeComponent {
  isLogged = signal(false);
  isShowMenu = false;

  topNavMenu = [
    {
      label: 'HOME',
      url: '',
    },
    {
      label: 'PRODUCTION',
      url: 'production',
    },
    {
      label: 'COST PREVIEW',
      url: 'service-catalog',
    },
    {
      label: 'API INTEGRATION',
      url: 'api-integration',
    },
    {
      label: 'ABOUT US',
      url: 'about-us',
    },
  ];

  topLeftNavMenu = [
    {
      label: 'CONTACT US',
      url: 'contact-us',
    },
    {
      label: 'LOGIN',
      url: 'login',
    },
    {
      label: 'SIGN UP',
      url: 'register',
    },
  ];

  topLefNavMenuLogged = [
    {
      label: 'Welcome, <username>',
      url: '',
    },
    {
      label: 'Go to Dashboard',
      url: 'dashboard',
    },
  ];

  contactInfos = [
    {
      about1: 'Our Head Office',
      at1: 'Oakwood Park, Houston, TX 77040',
    },
    {
      about1: 'Contact Us',
      at1: 'it@PrintHubPod.com',
      details: [],
    },
  ];

  constructor(private authenticationService: AuthenticationService) {
    merge(this.authenticationService.user, of(this.authenticationService.userSession)).subscribe((user: any) => {
      this.isLogged.set(!!user);

      if (!user) {
        return;
      }

      const [contact] = this.topLeftNavMenu;
      const [welcome, logout] = this.topLefNavMenuLogged;
      this.topLeftNavMenu = [contact, { ...welcome, label: welcome.label.replace('<username>', user.name) }, logout];
    });
  }

  logout() {
    this.authenticationService.logout();
  }
}
