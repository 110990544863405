<app-wallet-toolbar></app-wallet-toolbar>
<br />
<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="grid-container">
  <div class="card mr-3">
    <div class="total-balance">
      Total Balance: <span>{{ totalBalance | currency : "USD" : "symbol" }}</span>
    </div>
    <div class="table-container">
      <p-table [value]="userBalance" [paginator]="false" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th pSortableColumn="user_name">User <p-sortIcon field="user_name"></p-sortIcon></th>
            <th style="text-align: right" pSortableColumn="balance">Balance <p-sortIcon field="balance"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
          <tr class="data-row">
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ i.user_name }}</td>
            <td style="text-align: right">{{ i.balance | currency : "USD" : "symbol" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="card">
    <p-toolbar>
      <div class="p-toolbar-group-start">
        <p-button icon="pi pi-wallet" label="Deduct Money" severity="danger" class="p-button-warning" (click)="openDeductDialog()" />
      </div>
      <div class="p-toolbar-group-center">
        <label for="userNameFilter" class="mr-2">Search transaction by</label>
        <input id="userNameFilter" class="mr-2" pInputText placeholder="User name ..." [(ngModel)]="filters.userName" />
        <label for="startDateFilter" class="mr-2">Duration</label>
        <p-calendar
          id="startDateFilter"
          selectionMode="range"
          [readonlyInput]="true"
          [showIcon]="true"
          [showOnFocus]="true"
          [showButtonBar]="true"
          inputId="buttondisplay"
          [(ngModel)]="filters.dateRange"
          [maxDate]="filters.maxDate"
          dateFormat="MM dd, yy"
          [inputStyle]="{ width: '250px' }"
          class="mr-2"
        ></p-calendar>
        <button pButton class="pi pi-search" class="mr-2" (click)="onSearch()">Search</button>
        <button pButton class="pi pi-times" class="mr-2" (click)="onClear()">Clear</button>
      </div>
      <div class="p-toolbar-group-end"></div>
    </p-toolbar>
    <div class="table-container">
      <p-table [value]="transactions" [paginator]="false" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id">#</th>
            <th pSortableColumn="user_name">User</th>
            <th pSortableColumn="transfer_type">Type <p-sortIcon field="transfer_type"></p-sortIcon></th>
            <th style="text-align: right" pSortableColumn="balance">Start Balance</th>
            <th style="text-align: right" pSortableColumn="amount">Amount</th>
            <th>Created Time</th>
            <th>Modified Time</th>
            <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
            <th>Notes</th>
            <th style="text-align: center">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i>
          <tr class="data-row">
            <td>{{ i.id }}</td>
            <td>{{ i.user_name }}</td>
            <td>{{ i.transfer_type }}</td>
            <td style="text-align: right">{{ i.balance | currency : "USD" : "symbol" }}</td>
            <td style="text-align: right" [class.status-rejected]="i.amount < 0" nowrap>{{ i.amount | currency : "USD" : "symbol" }}</td>
            <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
            <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
            <td>
              <span [ngSwitch]="i.status">
                <p *ngSwitchCase="'Approved'" class="status-approved">{{ i.status }}</p>
                <p *ngSwitchCase="'Rejected'" class="status-rejected">{{ i.status }}</p>
                <p *ngSwitchCase="'Cancelled'" class="status-cancelled">{{ i.status }}</p>
                <p *ngSwitchDefault class="status-other">{{ i.status }}</p>
              </span>
            </td>
            <td>
              <div *ngIf="i.note">
                <i class="pi pi-clipboard" style="cursor: pointer" pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(i.note)"></i>
                <i pTooltip="{{ i.note }}" tooltipPosition="top">{{ i.note | slice : 0 : 30 }}</i>
              </div>
            </td>
            <td style="width: 120px; text-align: center">
              <button
                *ngIf="i.status === 'Requested'"
                pButton
                type="button"
                icon="pi pi-check-circle"
                pTooltip="Approve"
                tooltipPosition="top"
                (click)="approveTransaction(i)"
                class="p-button-rounded p-button-success small-button"
              ></button>
              <button
                *ngIf="i.status === 'Requested'"
                pButton
                type="button"
                icon="pi pi-times"
                pTooltip="Reject"
                tooltipPosition="top"
                (click)="rejectTransaction(i)"
                class="p-button-rounded p-button-danger small-button"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <p-paginator
      [rows]="20"
      [totalRecords]="totalTransactions"
      [rowsPerPageOptions]="[20, 50, 100]"
      [showFirstLastIcon]="true"
      (onPageChange)="onPageChange($event)"
      currentPageReportTemplate="Showing {first} to {last} of {{ totalTransactions }}"
      [showCurrentPageReport]="true"
    ></p-paginator>
  </div>
</div>
<p-toast></p-toast>

<p-dialog header="Deduct Money from a user" [modal]="true" [(visible)]="showDeductDialog" [style]="{ width: '600px' }">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="amount" class="font-semibold w-8rem">Deduct Amount</label>
    <p-inputNumber
      id="amount"
      [(ngModel)]="newTransaction.amount"
      class="flex-auto"
      [min]="0"
      [max]="10000"
      autoFocus="true"
      mode="currency"
      currency="USD"
      locale="en-US"
    ></p-inputNumber>
  </div>
  <div class="flex align-items-center gap-3 mb-5">
    <label for="note" class="font-semibold w-8rem">User ID</label>
    <input pInputText id="note"  [(ngModel)]="newTransaction.user_name" placeholder="Enter User ID to deduct money" class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex align-items-center gap-3 mb-5">
    <label for="note" class="font-semibold w-8rem">Note</label>
    <input pInputText id="note" [(ngModel)]="newTransaction.note" placeholder="Your note for this deduction" class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Request for Deduction" (onClick)="submitDeduction()"></p-button>
  </div>
</p-dialog>
