import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, Observable, of, switchMap, tap } from 'rxjs';
import { SettingService } from './setting.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private apiUrl = `${environment.apiUrl}/wallet`;
  private stripe: Stripe | null = null;

  constructor(private http: HttpClient, private settingService: SettingService) {}

  loadStripe(): Observable<any> {
    if (!this.stripe) {
      return this.settingService.getFirstSetting('STRIPE_PUBLISHABLE_KEY').pipe(
        switchMap((publishableKey: string) => from(loadStripe(publishableKey))),
        tap(stripe => {
          this.stripe = stripe;
          if (!this.stripe) {
            console.error('Failed to load Stripe instance');
          }
        }),
        catchError(err => {
          console.error('Failed to load Stripe key:', err);
          return EMPTY;
        })
      );
    }
    return of(undefined); // return an observable of undefined if stripe is already loaded
  }


  getStripeInstance(): Stripe | null {
    return this.stripe;
  }

  createPaymentIntent(amount: number, currency: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/create-payment-intent`, { amount, currency });
  }

  async confirmCardPayment(clientSecret: string, cardElement: any): Promise<any> {
    if (!this.stripe) throw new Error('Stripe has not been initialized');

    return this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
  }
}
