<app-wallet-toolbar></app-wallet-toolbar>
<br />
<p-toolbar>
  <p-button icon="pi pi-plus" label="Deposit Money" severity="success" class="p-button-success" (click)="showDepositMoneyDialog()" />
  <div class="total-balance">
    My Balance: <span> {{ myBalance | currency : "USD" : "symbol" }}</span>
  </div>
</p-toolbar>

<p-dialog header="Deposit Your Money" [modal]="true" [(visible)]="displayDepositDialog" [style]="{ width: '600px' }">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="amount" class="font-semibold w-8rem">Amount</label>
    <p-inputNumber
      id="amount"
      class="flex-auto"
      [(ngModel)]="newTransaction.amount"
      [min]="10"
      [max]="50000"
      autoFocus="true"
      mode="currency"
      currency="USD"
      locale="en-US"
    ></p-inputNumber>
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="transferType" class="font-semibold w-8rem">Transfer Type</label>
    <p-radioButton
      *ngFor="let option of transferTypes"
      [value]="option"
      [(ngModel)]="newTransaction.transfer_type"
      [label]="option"
      [name]="'transferType'"
      class="mr-2"
      (ngModelChange)="onTransferTypeChange($event)"
    ></p-radioButton>
  </div>
  <div *ngIf="displayPayByCard">
    <div class="flex align-items-center gap-6 mb-3">
      <label class="font-semibold w-8rem"></label>
      <div id="card-element"></div>
    </div>
    <div class="flex align-items-center gap-3 mb-3">
      <label class="font-semibold w-8rem"></label>
      <div id="card-errors" role="alert"></div>
    </div>
  </div>
  <div class="flex align-items-center gap-3 mb-5">
    <label for="note" class="font-semibold w-8rem">Note</label>
    <input pInputText id="note" [(ngModel)]="newTransaction.note" placeholder="Your note, transaction number, ..." class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Cancel" severity="secondary" (onClick)="displayDepositDialog = false"></p-button>
    <p-button [label]="displayPayByCard ? 'Pay & Submit' : 'Submit'" (onClick)="onSubmitDeposit()"></p-button>
  </div>
</p-dialog>

<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="card">
  <p-table [value]="transactions" [paginator]="false" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">#<p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="transfer_type">Type <p-sortIcon field="transfer_type"></p-sortIcon></th>
        <th style="text-align: right" pSortableColumn="balance">Start Balance <p-sortIcon field="balance"></p-sortIcon></th>
        <th style="text-align: right" pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
        <th pSortableColumn="created_time">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
        <th pSortableColumn="modified_time">Modified Time <p-sortIcon field="modified_time"></p-sortIcon></th>
        <th pSortableColumn="change_by">Change By <p-sortIcon field="change_by"></p-sortIcon></th>
        <th pSortableColumn="order_id">Order ID</th>
        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
        <th>Notes</th>
        <th style="text-align: center">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
      <tr class="data-row">
        <td>{{ i.id }}</td>
        <td>{{ i.transfer_type }}</td>
        <td style="text-align: right">{{ i.balance | currency : "USD" : "symbol" }}</td>
        <td style="text-align: right">{{ i.amount | currency : "USD" : "symbol" }}</td>
        <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ i.change_by }}</td>
        <td>{{ i.order_id }}</td>
        <td>
          <span [ngSwitch]="i.status">
            <p *ngSwitchCase="'Approved'" class="status-approved">{{ i.status }}</p>
            <p *ngSwitchCase="'Rejected'" class="status-rejected">{{ i.status }}</p>
            <p *ngSwitchCase="'Cancelled'" class="status-cancelled">{{ i.status }}</p>
            <p *ngSwitchDefault class="status-other">{{ i.status }}</p>
          </span>
        </td>
        <td>
          <div *ngIf="i.note">
            <i class="pi pi-clipboard" style="cursor: pointer" pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(i.note)"></i>
            <i pTooltip="{{ i.note }}" tooltipPosition="top">{{ i.note | slice : 0 : 30 }}</i>
          </div>
        </td>
        <td style="width: 100px; text-align: center">
          <button
            *ngIf="i.status === 'Requested' && i.type === 'fund'"
            pButton
            type="button"
            icon="pi pi-times"
            pTooltip="Cancel"
            tooltipPosition="top"
            (click)="cancelTransaction(i)"
            class="p-button-rounded p-button-danger small-button"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    [rows]="20"
    [totalRecords]="totalTransactions"
    [rowsPerPageOptions]="[20, 50, 100]"
    [showFirstLastIcon]="true"
    (onPageChange)="onPageChange($event)"
    currentPageReportTemplate="Showing {first} to {last} of {{ totalTransactions }}"
    [showCurrentPageReport]="true"
  ></p-paginator>
</div>
<p-toast></p-toast>
