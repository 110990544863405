<p-tabView #mainTabView [(activeIndex)]="activeIndex">
  <p-tabPanel class="tabPanel">
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-list" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0">Import Orders</span>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="content-container d-flex">
        <!-- Left Column: p-fileUpload -->
        <div class="file-upload-column">
          <p-fileUpload
            name="cvsFiles"
            multiple="true"
            accept=".csv"
            [customUpload]="true"
            (uploadHandler)="onUploadFiles($event)"
            (onSelect)="onFilesSelected($event)"
            chooseLabel="Choose CSV Files ..."
            uploadLabel="Upload"
          ></p-fileUpload>
          <br />
          <a href="https://ezprintifyprod.blob.core.windows.net/files/templates/Import%20Order%20Template.xlsx" target="_blank">
            <p-button label="Download template file" icon="pi pi-download" severity="success"> </p-button>
          </a>
        </div>

        <!-- Right Column: p-table and p-paginator -->
        <div class="table-column card">
          <p-table [value]="orderCollections" [scrollable]="true" scrollHeight="flex" [paginator]="false">
            <ng-template pTemplate="header">
              <tr>
                <th class="nowrap-header">ID</th>
                <th class="nowrap-header">Vendor</th>
                <th class="nowrap-header">Shop Id</th>
                <th class="nowrap-header">Total Order</th>
                <th class="nowrap-header">Processed</th>
                <th class="nowrap-header">Skipped</th>
                <th class="nowrap-header">Status</th>
                <th class="nowrap-header">Import Details</th>
                <th class="nowrap-header">Created By</th>
                <th pSortableColumn="created_time" class="nowrap-header">
                  Created Time
                  <p-sortIcon field="created_time"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
              <tr class="data-row">
                <td>{{ i.id }}</td>
                <td>{{ i.vendor }}</td>
                <td>{{ i.shop_id }}</td>
                <td>{{ i.total_order }}</td>
                <td>{{ i.processed_order }}</td>
                <td>{{ i.skipped_order }}</td>
                <td>{{ i.status }}</td>
                <td>
                  <p-button label="View Import Details" [severity]="i.skipped_order === 0 ? 'success' : 'warning'" (onClick)="showImportDetailDialog(i)">
                  </p-button>
                </td>
                <td>{{ i.created_by?.userName }}</td>
                <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
              </tr>
            </ng-template>
          </p-table>
          <p-paginator
            #paginator
            [rows]="defaultPageLimit"
            [totalRecords]="totalCollections"
            [rowsPerPageOptions]="[defaultPageLimit, 50, 100]"
            [showFirstLastIcon]="true"
            (onPageChange)="onPageChange($event)"
            currentPageReportTemplate="Showing {first} to {last} of {{ totalCollections }}"
            [showCurrentPageReport]="true"
          ></p-paginator>
        </div>
      </div>

      <p-dialog [(visible)]="viewImportDetailDialog" [header]="'Import Details'" [modal]="true" [style]="{ width: '50vw' }" [closable]="true">
        <p-table [value]="selectedDetails" [scrollable]="true" scrollHeight="flex" [paginator]="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="nowrap-header">#</th>
              <th class="nowrap-header">Order ID</th>
              <th class="nowrap-header">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
            <tr class="data-row">
              <td>{{ rowIndex + 1 }}</td>
              <td>{{ i.orderId }}</td>
              <td>{{ i.reason }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-dialog>
    </ng-template>
  </p-tabPanel>
  <!--
  <p-tabPanel>
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-list" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0">Pushed Order Collections</span>
    </ng-template>
    <ng-template pTemplate="content">
      <p-toolbar>
        <div class="p-toolbar-group-start">
          <p-button
            icon="pi pi-sparkles"
            label="Process incomplete collections"
            class="p-button-success"
            severity="success"
            (click)="processIncompleteCollections()"
          />
        </div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end"></div>
      </p-toolbar>
      <p-table [value]="orderCollections" [scrollable]="true" scrollHeight="flex" [paginator]="false">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th class="nowrap-header">Id</th>
            <th class="nowrap-header">Vendor</th>
            <th class="nowrap-header">Shop Id</th>
            <th class="nowrap-header">Total Order</th>
            <th class="nowrap-header">Processed Order</th>
            <th class="nowrap-header">Skipped Order</th>
            <th class="nowrap-header">Status</th>
            <th class="nowrap-header">Comment</th>
            <th class="nowrap-header">Created By</th>
            <th pSortableColumn="created_time" class="nowrap-header">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
            <th pSortableColumn="modified_time" class="nowrap-header">Modified Time <p-sortIcon field="modified_time"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
          <tr class="data-row">
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ i.id }}</td>
            <td>{{ i.vendor }}</td>
            <td>{{ i.shop_id }}</td>
            <td>{{ i.total_order }}</td>
            <td>{{ i.processed_order }}</td>
            <td>{{ i.skipped_order }}</td>
            <td>{{ i.status }}</td>
            <td>{{ i.comment }}</td>
            <td>{{ i.created_by?.userName }}</td>
            <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
            <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-list" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0">External Orders</span>
    </ng-template>
    <ng-template pTemplate="content">
      <p-toolbar>
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end"></div>
      </p-toolbar>
      <p-table [scrollable]="true" scrollHeight="flex" [paginator]="false">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th class="nowrap-header">Order Id</th>
            <th class="nowrap-header">Status</th>
            <th class="nowrap-header">Shipping Type</th>
            <th pSortableColumn="created_time" class="nowrap-header">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
            <th style="text-align: center; width: 100px">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
          <tr class="data-row">
            <td>{{ rowIndex + 1 }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                pButton
                type="button"
                pTooltip="Update Connector"
                tooltipPosition="top"
                icon="pi pi-pen-to-square"
                class="p-button-rounded small-button mr-2"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel [disabled]="true">
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-cog" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0">Connectors</span>
    </ng-template>
    <ng-template pTemplate="content">
      <p-toolbar>
        <div class="p-toolbar-group-start">
          <p-button icon="pi pi-shop" label="New Etsy Connector" severity="success" class="p-button-success" class="mr-2" />
          <p-button icon="pi pi-tiktok" label="New TikTok Connector" severity="success" class="p-button-success" class="mr-2" />
        </div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end"></div>
      </p-toolbar>
      <p-table [scrollable]="true" scrollHeight="flex" [paginator]="false">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th class="nowrap-header">Vendor</th>
            <th class="nowrap-header">App Key</th>
            <th class="nowrap-header">App Secret</th>
            <th class="nowrap-header">Client ID</th>
            <th pSortableColumn="created_time" class="nowrap-header">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
            <th pSortableColumn="modified_time" class="nowrap-header">Modified Time <p-sortIcon field="modified_time"></p-sortIcon></th>
            <th style="text-align: center; width: 100px">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
          <tr class="data-row">
            <td>{{ rowIndex + 1 }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                pButton
                type="button"
                pTooltip="Update Connector"
                tooltipPosition="top"
                icon="pi pi-pen-to-square"
                class="p-button-rounded small-button mr-2"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-tabPanel>
  -->
</p-tabView>
<p-toast></p-toast>
