import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QualityControlService } from './quality.control.service';
import { SharePrimeNGModule } from '../share-primeng.module';
import * as _ from 'underscore';
import { AuthenticationService } from '../services/authentication.service';
import { ImageComponent } from '../shared-ui/common/image/image.component';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ConversationService } from '../services/conversation.service';
import { UtilityService } from '../services/utility.service';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { ConversationDialogComponent } from '../shared-ui/conversation-dialog/conversation-dialog.component';

@Component({
  selector: 'app-quality-control',
  standalone: true,
  imports: [SharePrimeNGModule, ImageComponent, PdfViewerComponent],
  providers: [DialogService, MessageService],
  templateUrl: './quality-control.component.html',
  styleUrl: './quality-control.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QualityControlComponent {
  ref: DynamicDialogRef | undefined;
  orderId: number = 0;
  order!: any;
  commentCount = 0;
  sellerInfo = '';
  staffInfo = '';
  loginUserName: any;
  refreshCommentCount$ = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private qualityControlService: QualityControlService,
    private authService: AuthenticationService,
    private dialogService: DialogService,
    private conversationService: ConversationService,
    private utilityService: UtilityService
  ) {
    const orderId = this.route.snapshot.queryParams['id'];
    this.orderId = orderId;
    this.order = {
      id: 0,
      order_variants: [],
      seller_name: '',
      shipping_label: '',
      status: '',
      ticket_id: '',
      tracking_number: '',
      verified_by: '',
      user: '',
      totalItem: 0,
      created_time: new Date(),
    };
    this.getOrderDetail(orderId);

    authenticationService.user.subscribe((user) => {
      this.loginUserName = user;
    });
    this.loginUserName = this.authenticationService.userSession?.userName;

    this.refreshCommentCount$.subscribe(() => this.getCommentCount(orderId));
  }

  getOrderDetail(orderId: number) {
    this.qualityControlService.getOrderDetail(orderId).subscribe({
      next: (response: any) => {
        this.order = JSON.parse(JSON.stringify(response));
        this.order.user = this.authService?.userSession?.userName;
        this.order.totalItem = _.reduce(this.order.order_variants, (sum, item) => sum + item.quantity, 0);

        this.getCommentCount(orderId);
        this.getSellerStaffDetail(this.order.created_by, this.order.verified_by);
      },
      error: (error) => {
        this.sendError('Get order detail!', `Failed to perform the action: ${error}`);
      },
    });
  }

  getCommentCount(orderId: number) {
    this.qualityControlService.countConversationComment(orderId).subscribe({
      next: (response: any) => {
        this.commentCount = response;

        // Show conversation dialog if any
        if (this.commentCount > 0) {
          this.openConversationDialog();
        }
      },
      error: (error) => {
        this.sendError('Count conversation comments!', `Failed to perform the action: ${error}`);
      },
    });
  }

  getSellerStaffDetail(sellerId: number, staffName: string) {
    if (sellerId) {
      this.qualityControlService.getSellerDetail(sellerId).subscribe((response: any) => {
        this.sellerInfo = `${response.username} (${response.rolename})`;
      });
    }
    if (staffName) {
      this.qualityControlService.getStaffDetail(staffName).subscribe((response: any) => {
        this.staffInfo = `${response.username} (${response.rolename})`;
      });
    }
  }

  shipOrder() {
    const order = this.order;
    this.qualityControlService.shipOrder(this.orderId, this.loginUserName).subscribe({
      next: (data) => {
        this.printOrder();
        this.getOrderDetail(this.orderId);
      },
      error: (error) => {
        this.sendError('Update order status!', `Failed to perform the action: ${error}`);
      },
    });
  }

  printOrder() {
    const order = this.order;
    this.utilityService
      .lookupReplacementUrl(order.shipping_label)
      .pipe(
        tap((fn) => {
          this.ref = this.dialogService.open(PdfViewerComponent, {
            header: 'Print Label',
            width: '85vw',
            contentStyle: { overflow: 'auto' },
            data: {
              order,
              url: this.utilityService.getBypassCorURL(fn(order.shipping_label)),
            },
          });
        })
      )
      .subscribe();
  }

  openConversationDialog() {
    this.ref = this.dialogService.open(ConversationDialogComponent, {
      showHeader: false,
      closable: true,
      width: '70vw',
      contentStyle: { overflow: 'auto' },
      data: {
        orderId: this.orderId,
        refreshCommentCount$: this.refreshCommentCount$,
      },
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
