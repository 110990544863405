import { Component } from '@angular/core';
import { QualityControlService } from '../../quality-control/quality.control.service';
import { MessageService } from 'primeng/api';
import { Conversation } from '../../models/conversation.model';
import { ConversationService } from '../../services/conversation.service';
import { AuthenticationService } from '../../services/authentication.service';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-conversation-dialog',
  standalone: true,
  imports: [SharePrimeNGModule],
  providers: [MessageService],
  templateUrl: './conversation-dialog.component.html',
  styleUrl: './conversation-dialog.component.css'
})
export class ConversationDialogComponent {
  commentEditor = '';
  conversation: Conversation = {
    id: 0,
    comments: [],
  };
  loginUserName: any;

  constructor(public ref: DynamicDialogRef, private config: DynamicDialogConfig, private qualityControlService: QualityControlService, private messageService: MessageService, private conversationService: ConversationService, private authenticationService: AuthenticationService,) {
    this.loginUserName = this.authenticationService.userSession?.userName;

    this.qualityControlService.getConversation(this.config.data.orderId).subscribe((response: any) => {
      this.conversation = response;
    });
  }

  sendNewConversationComment() {
    const c = this.conversation;
    this.conversationService
      .addComment({
        id: c.id,
        first_comment: c.comments?.length === 0 ? this.extractTextFromHTML(this.commentEditor) : '',
        last_comment: c.comments?.length > 0 ? this.extractTextFromHTML(this.commentEditor) : '',
        comment: this.commentEditor,
        created_by: this.loginUserName,
      })
      .subscribe({
        next: (addedComment) => { 
          c.comments.push(addedComment);
          this.config.data.refreshCommentCount$.next();
        },
        error: (err) => {
          this.sendError('Error!', `Failed to add comment due to an error: ${err}`);
        },
      });

    this.commentEditor = '';
  }

  extractTextFromHTML(html: string, maxLength: number = 150): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || '';
    if (textContent.length > maxLength) {
      return textContent.slice(0, maxLength);
    }
    return textContent;
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
