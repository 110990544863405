import { Component, Inject, Input, OnChanges, OnDestroy, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { map, Subscription, switchMap, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { SharePrimeNGModule } from '../../../share-primeng.module';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [SharePrimeNGModule],
  templateUrl: './image.component.html',
  styleUrl: './image.component.css',
})
export class ImageComponent implements OnChanges, OnDestroy {
  @Input()
  width: number = 50;

  @Input()
  height: number = 50;

  @Input()
  src: string = '';

  @Input()
  context: { orderId?: number; orderVariantId?: number, retainUrl?: boolean, disabledPreview?: boolean } | undefined;

  @Input()
  text: string = '';

  @Input()
  showQr: boolean = false;

  imageSrc: any;
  isImageLoading = true;

  originalSrc: string = '';

  subscriptions = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient, private utilityService: UtilityService) { }

  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.context && !this.src) {
        return;
      }

      this.originalSrc = this.src;

      if (this.context && this.context.orderId && this.context.orderVariantId) {
        this.src = `${environment.cdnUrl}/${this.context?.orderId}/${this.context?.orderVariantId}_${this.text}-thumbnail.jpg?t=${new Date().getTime()}`;
      }

      if (!this.context?.retainUrl) {
        this.originalSrc = this.src.replace(/-thumbnail-?/g, '').replace('.jpg', '.png');
      }

      this.subscriptions.add(
        this.utilityService.lookupReplacementUrl(this.src).pipe(
          map(fn => fn(this.src)),
          switchMap(url =>
            this.http.get(url, {
              responseType: 'blob',
            })
          ),
          tap(() => {
            this.isImageLoading = false;
          })
        ).subscribe({
          next: (data) => {
            this.createImageFromBlob(data);
          },
          error: (error) => { },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  readonly createImageFromBlob = (image: Blob | any) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.imageSrc = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  };
}
