import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-wallet-toolbar',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './wallet-toolbar.component.html',
  styleUrl: './wallet-toolbar.component.css',
})
export class WalletToolbarComponent {
  items: MenuItem[] = [];
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.items = [
      { label: 'My Wallet Transaction', icon: 'pi pi-fw pi-calendar', routerLink: '/wallet/my-wallet' },
      { label: 'Wallet Dashboard', icon: 'pi pi-fw pi-warehouse', routerLink: '/wallet/dashboard' },
    ];

    this.items = this.items.filter(item => this.checkPermission(item));
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  checkPermission(item: any) {
    if (this.authenticationService.userSession) {
      const permissions: string[] = this.authenticationService.userSession.permissions;
      let hasPermission = false;
      permissions.some((value: any, index) => {
        hasPermission = value.access_paths?.some((p: any) => p == item.routerLink);
        if (hasPermission) {
          return hasPermission;
        }
        return false;
      });
      return hasPermission;
    }
    return false;
  }
}
