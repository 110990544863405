import { Component, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { SharePrimeNGModule } from '../../../share-primeng.module';
import { ToolbarComponent, ToolbarModel } from '../toolbar/toolbar.component';
import { TYPES, ColumnModel, FilterConfigModel, ViewConfigModel } from './table.model';
import { Subscription } from 'rxjs';
import { ImageComponent } from '../image/image.component';
import { SimplePcTableComponent } from '../simple-pc-table/simple-pc-table.component';
import { TimelineComponent } from '../timeline/timeline.component';
import moment from 'moment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    SharePrimeNGModule,
    ToolbarComponent,
    ReactiveFormsModule,
    ImageComponent,
    SimplePcTableComponent,
    TimelineComponent
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
  host: { ngSkipHydration: 'true' },
})
export class TableComponent implements OnDestroy {
  @Input() toolbarConfigs: ToolbarModel | undefined;
  @Input() data: any[] = [];
  @Input() cols!: ColumnModel[];
  @Input() viewConfigs: ViewConfigModel | undefined;
  @Input() filterConfigs: FilterConfigModel<any>[] = [];
  @Input() totalRecords: number = 0;
  @Input() minWidth = '';

  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() openConversationDialog: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<any> = new EventEmitter();
  @Output() onViewConvertLabel: EventEmitter<any> = new EventEmitter();
  @Output() onChangeStatusOnAir: EventEmitter<any> = new EventEmitter();

  @ViewChild('idColTemplate', { static: true }) idColTemplate: TemplateRef<any> | undefined;
  @ViewChild('commonTemplate', { static: true }) commonTemplate: TemplateRef<any> | undefined;
  @ViewChild('designItemTemplate', { static: true }) designItemTemplate: TemplateRef<any> | undefined;
  @ViewChild('trackingTemplate', { static: true }) trackingTemplate: TemplateRef<any> | undefined;
  @ViewChild('currencyTemplate', { static: true }) currencyTemplate: TemplateRef<any> | undefined;
  @ViewChild('simpleCurrencyTemplate', { static: true }) simpleCurrencyTemplate: TemplateRef<any> | undefined;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any> | undefined;
  @ViewChild('durationTemplate', { static: true }) durationTemplate: TemplateRef<any> | undefined;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any> | undefined;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any> | undefined;
  @ViewChild('booleanTemplate', { static: true }) booleanTemplate: TemplateRef<any> | undefined;

  formGroup: FormGroup;
  TYPES = TYPES;
  isShowFilter = false;
  filters: any[] | undefined;
  first = 0;
  @Input()
  rows = 10;
  expandedRows = {};

  sortField: string = '';
  fulfillStatus: any = {};

  subscriptions: Subscription = new Subscription();

  orderItems: any[] = [];
  orderCostTableCols = [
    { field: 'name', header: 'Item' },
    { field: 'oversizePrice', header: 'Oversize', type: 'currency' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'price', header: 'Unit Price', type: 'currency' },
    { field: 'total', header: 'Line Total', type: 'currency' }
  ];
  timelines: any[] = [];

  maxDate = new Date();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.formGroup = new FormGroup({}, { updateOn: 'blur' });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId)) {
      const totalRecords = changes['totalRecords'];
      if (totalRecords?.currentValue !== totalRecords?.previousValue) {
        this.first = 0;
      }

      this.filters = this.filterConfigs?.reduce((acc: any, filter: any) => {
        const sum = (values: number[]) => values.reduce((acc, cur) => acc + cur, 0);
        const currentIdxSubFilters = (acc.length || 1) - 1;
        if (!acc[currentIdxSubFilters]) {
          acc.push([]);
        }

        const currentSubFilters: any = acc[currentIdxSubFilters];
        const occupiedSpace = sum(currentSubFilters.map(({ occupy }: any) => occupy));

        if (occupiedSpace + filter.occupy <= 12) {
          currentSubFilters.push(filter);
          return acc;
        }

        const newSubFilters = [filter];
        acc.push(newSubFilters);
        return acc;
      }, []);

      const tempStatus: any = this.filterConfigs.find(({ key }: any) => key === 'fulfillStatus') || {};
      const userInfo = JSON.parse(localStorage.getItem('ez-user')!);

      this.fulfillStatus = {
        ...tempStatus,
        options: (tempStatus?.options || []).filter(({ value }: any) => value).map((option: any) => {
          return {
            ...option,
            disabled: !userInfo.roles.includes('Admin') && option.value !== 'Cancelled'
          };
        })
      }

      this.filterConfigs?.filter((field: any) => field.type !== TYPES.BUTTONGROUP).forEach((field: any) => {
        const formControl = new FormControl();
        this.formGroup.addControl(field.key, formControl);
      });

      this.sortField = this.cols.find(({ sortable }: any) => sortable)?.field || '';

      if (changes['toolbarConfigs']?.currentValue) {
        if (this.toolbarConfigs?.rightButtons) {
          this.toolbarConfigs?.rightButtons[0].event.subscribe(() => {
            this.isShowFilter = !this.isShowFilter;
            const state = {
              'true': {
                icon: 'pi-filter',
                label: 'Show Filter'
              },
              'false': {
                icon: 'pi-filter-slash',
                label: 'Hide Filter'
              }
            };

            const [filterButton] = this.toolbarConfigs?.rightButtons!;
            filterButton.text = this.isShowFilter;
            filterButton.icon = state[`${!this.isShowFilter}`].icon;
            filterButton.label = state[`${!this.isShowFilter}`].label;
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPageChange(event: any) {
    if (this.first !== event.first || this.rows !== event.rows) {
      this.first = event.first;
      this.rows = event.rows;
      this.pageChange.emit(event);
    }
  }

  onSort(event: any) {
    this.sort.emit(event);
  }

  copyValue(field: string, value: string) {
    navigator.clipboard.writeText(value);
    alert(`Copied the ${field}!`);
  }

  getTemplateRef(templateName: string): TemplateRef<any> {
    return (this as any)[`${templateName}`];
  }

  constructPriceBreakdown = (order: any) => {
    const { shippingPrice, shippingPriceFor2ndItem, priorityPrice, oversizePrice, platformFee, itemPrice } = order.priceBreakdown;
    let totalQty = 0;
    this.orderItems = [...order.order_variants.map((item: any, idx: number) => {
      const { quantity, ...designPrices }: any = itemPrice[idx];
      const { product_name, variant } = item;
      const { style, color, size } = variant;

      totalQty += quantity;
      return {
        data: {
          name: `${product_name} (${style} - ${color} - ${size}) - Qty: ${quantity}`
        },
        children: Object.keys(designPrices).map(name => ({
          data: {
            name,
            oversizePrice,
            quantity,
            price: designPrices[name],
            total: (designPrices[name] + (oversizePrice || 0) * name.split('+').length) * quantity
          }
        }))
      };
    }),
    {
      data: {
        name: 'Logistic Fee',
      },
      children: [
        {
          data: {
            name: 'Priority Order',
            quantity: priorityPrice ? 1 : 0,
            price: priorityPrice
          }
        },
        {
          data: {
            name: 'Platform Fee',
            quantity: order.is_platform ? 1 : 0,
            price: platformFee
          }
        },
        {
          data: {
            name: 'Shipping Price',
            quantity: order.is_platform ? 0 : 1,
            price: shippingPrice
          }
        },
        {
          data: {
            name: 'Shipping Price from the 2nd item',
            quantity: order.is_platform ? 0 : totalQty - 1,
            price: shippingPriceFor2ndItem
          }
        },
      ].map((item: any) => ({
        data: {
          ...item.data,
          total: (item.data.price + (item.data.oversizePrice || 0) * item.data.name.split('+').length) * item.data.quantity
        }
      }))
    }];

    this.orderItems.push(
      {
        data: {
          name: 'Total'
        },
        children: [
          {
            data: {
              name: '',
              total: this.orderItems.reduce((acc, cur) => {
                return acc + cur.children.reduce((acc: number, cur: any) => {
                  return acc + cur.data.total;
                }, 0);
              }, 0)
            }
          }
        ]
      });
  };

  constructTimelines = (order: any) => {
    this.timelines = order.timelines.sort((a: any, b: any) => moment(a.modified_time).diff(b.modified_time)).map((item: any) => ({
      status: item.status,
      date: moment(item.modified_time).format('MMM D, YYYY, LT'),
      user: `${item.modified_by.firstName} ${item.modified_by.lastName} (${item.modified_by.userName})`
    }))
  }

  changeStatus(orderId: number, status: string) {
    this.onChangeStatusOnAir.emit({ orderId, status });
  }
}
