import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WalletToolbarComponent } from '../../shared-ui/wallet-toolbar/wallet-toolbar.component';
import { WalletService } from '../../services/wallet.service';
import { TransactionStatus, TransactionType, WalletTransaction, WalletUserBalance } from '../../models/wallet.model';
import { FormsModule } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

interface Filter {
  userName: string;
  dateRange: any | null;
  maxDate: Date;
}

@Component({
  selector: 'app-wallet-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, SharePrimeNGModule, WalletToolbarComponent],
  providers: [MessageService],
  templateUrl: './wallet-dashboard.component.html',
  styleUrl: './wallet-dashboard.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WalletDashboardComponent {
  transactions: WalletTransaction[] = [];
  userBalance: WalletUserBalance[] = [];
  totalBalance: number = 0;
  totalTransactions: number = 0;
  filters: Filter = {
    userName: '',
    dateRange: [],
    maxDate: new Date(),
  };
  userName: any;
  isLoading = false;
  showDeductDialog = false;
  newTransaction!: WalletTransaction;

  pageEvent: any;

  constructor(private walletService: WalletService, private authenticationService: AuthenticationService, private messageService: MessageService) {
    authenticationService.user.subscribe((user) => {
      this.userName = user;
    });
    this.userName = this.authenticationService.userSession;

    this.createDefaultTransaction();
  }

  ngOnInit(): void {
    this.getAllUserBalance();
    this.getTransactions();
  }

  createDefaultTransaction(): void {
    this.newTransaction = {
      id: 0,
      type: TransactionType.FUND,
      user_name: '',
      transfer_type: 'Deduction',
      balance: 0,
      amount: 0,
      change_by: '',
      status: TransactionStatus.REQUESTED,
      note: '',
      created_time: new Date(),
      modified_time: new Date(),
    };
  }

  onPageChange(event: any) {
    this.pageEvent = event;
    this.getTransactions(event.page * event.rows, event.rows);
  }

  getTransactions(offset: number = 0, limit: number = 20) {
    this.isLoading = true;
    const userName = this.filters.userName;
    let startDate = '';
    let endDate = '';
    if (this.filters.dateRange) {
      startDate = this.filters.dateRange[0] ? this.filters.dateRange[0].toISOString().split('T')[0] : '';
      endDate = this.filters.dateRange[1] ? this.filters.dateRange[1].toISOString().split('T')[0] : '';
    }

    this.walletService
      .findTransactions(offset, limit, userName, startDate, endDate, 'fund')
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.transactions = response.data.sort((a, b) => b.id - a.id);
          this.totalTransactions = response.total;
        },
        error: (error) => {
          this.sendError('Load wallet transactions!', error);
        },
      });
  }

  getAllUserBalance() {
    this.walletService.findAllUserBalance().subscribe({
      next: (response) => {
        this.userBalance = response.sort((a, b) => b.balance - a.balance);
        this.totalBalance = this.userBalance.reduce((sum, userBalance) => sum + userBalance.balance, 0);
      },
      error: (error) => {
        this.sendError('Load wallet balance!', `Unable to perform action: ${error}`);
      },
    });
  }

  openDeductDialog() {
    this.showDeductDialog = true;
  }

  submitDeduction() {
    this.newTransaction.change_by = this.userName.userName;

    if (!this.newTransaction.user_name) {
      this.sendError('Deduct Error', 'User ID is required');
      return;
    }

    if (!this.newTransaction.note.trim()) {
      this.sendError('Deduct Error', 'Deduct note is required');
      return;
    }

    if (this.newTransaction.amount <= 0) {
      this.sendError('Deduct Error', 'Deduct amount must be greater than 0');
      return;
    }
    this.newTransaction.amount = -this.newTransaction.amount;

    this.walletService.createTransaction(this.newTransaction).subscribe({
      next: () => {
        this.onSearch();
        this.getAllUserBalance();
      },
      error: (error) => {
        // Show original value
        this.newTransaction.amount = -this.newTransaction.amount;

        this.sendError('Create Deduction transaction!', error);
      },
      complete: () => {
        this.showDeductDialog = false;

        // Reset the form values
        this.createDefaultTransaction();
      },
    });
  }

  updateTransaction(updatedTransaction: WalletTransaction): void {
    const index = this.transactions.findIndex((t) => t.id === updatedTransaction.id);
    if (index !== -1) {
      this.transactions[index] = updatedTransaction;
    }
  }

  approveTransaction(t: WalletTransaction): void {
    const tCopy = { ...t };
    tCopy.status = TransactionStatus.APPROVED;
    this.walletService.updateTransaction(tCopy).subscribe({
      next: (tUpdated) => {
        this.sendInfo('Approve Transaction!', 'The transaction has been approved successfully!');
        this.getAllUserBalance();
        this.updateTransaction(tUpdated);
      },
      error: (error) => {
        this.sendError('Approve Transaction!', error);
      },
    });
  }

  rejectTransaction(t: WalletTransaction): void {
    const tCopy = { ...t };
    tCopy.status = TransactionStatus.REJECTED;
    this.walletService.updateTransaction(tCopy).subscribe({
      next: (tUpdated) => {
        this.sendInfo('Reject Transaction!', 'The transaction has been rejected successfully!');
        this.getAllUserBalance();
        this.updateTransaction(tUpdated);
      },
      error: (error) => {
        this.sendError('Reject Transaction!', error);
      },
    });
  }

  onSearch() {
    this.getTransactions();
  }

  onClear() {
    this.filters.userName = '';
    this.filters.dateRange = [];
    this.getTransactions();
  }

  copyToClipboard(note: string) {
    if (note) {
      navigator.clipboard
        .writeText(note)
        .then(() => {})
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  }

  sendError(summary: string, detail: any) {
    const errorMessage = detail?.messages?.length
      ? detail.messages.join(', ') // Join all messages for display
      : `${detail?.error || detail}`;

    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: errorMessage,
    });
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }
}
