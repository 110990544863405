<div class="h-full">
  <app-toolbar [configs]="toolbarConfigs">
    @if(isShowFilter) {
    <div class="col-12">
      @if(viewConfigs) {
      <div class="grid">
        @for(view of viewConfigs.views; track view; let last = $last) {
        <div class="sx:col-4 col">
          <p-button label="{{ view.name }} ({{ view.count }})" [text]="true" (click)="viewConfigs.action(view.value)" />
        </div>
        }
      </div>
      } @if(filters) {
      <form [formGroup]="formGroup">
        @for(subfilters of filters; track subfilters) {
        <div class="col-12">
          <div class="grid">
            @for(filter of subfilters; track filter.key) {
            <div class="col-12 {{ !filter.occupy ? 'md:col' : 'md:col-' + filter.occupy }}">
              @if([TYPES.DROPDOWN, TYPES.INPUT, TYPES.DATERANGE].includes(filter.type)) {
              <p-floatLabel>
                @if(TYPES.INPUT === filter.type) {
                <input class="w-full" pInputText id="{{ filter.key }}" [formControlName]="filter.key" />
                } @if(TYPES.DROPDOWN === filter.type) {
                <p-dropdown
                  [style]="{ width: '100%' }"
                  [options]="filter.options"
                  optionLabel="{{ filter.optionLabel }}"
                  inputId="{{ filter.key }}"
                  [filter]="filter.hasFilter"
                  optionValue="value"
                  filterBy="{{ filter.optionLabel }}"
                  [formControlName]="filter.key"
                  [loading]="!filter.options.length"
                  [resetFilterOnHide]="filter.hasFilter"
                />
                } @if(TYPES.DATERANGE === filter.type) {
                <p-calendar [style]="{ width: '100%' }" [formControlName]="filter.key" selectionMode="range" [readonlyInput]="true" [maxDate]="maxDate" />
                }
                <label for="{{ filter.key }}">{{ filter.label }}</label>
              </p-floatLabel>
              } @if(TYPES.BUTTONGROUP === filter.type) {
              <div class="grid">
                @for(button of filter.buttons; track button.label) {
                <div class="{{ !button.occupy ? 'col' : 'col-' + button.occupy }}">
                  <p-button
                    [style]="{ width: '100%' }"
                    label="{{ button.label }}"
                    [raised]="true"
                    [severity]="button.severity"
                    (click)="button.action && button.action(formGroup)"
                  />
                </div>
                }
              </div>
              }
            </div>
            }
          </div>
        </div>
        }
      </form>
      }
    </div>
    }
  </app-toolbar>

  <p-progressBar *ngIf="data.length === 0" mode="indeterminate" [style]="{ height: '3px' }" />
  <div class="card">
    <p-table
      dataKey="id"
      [lazy]="true"
      [value]="data"
      [columns]="cols"
      [scrollable]="true"
      [sortField]="sortField"
      [sortOrder]="-1"
      [totalRecords]="totalRecords"
      [tableStyle]="{ 'min-width': minWidth }"
      [paginator]="true"
      [rows]="rows"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      scrollHeight="flex"
      [first]="first"
      (onPage)="onPageChange($event)"
      (onSort)="onSort($event)"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for(col of columns; track col) {
          <th [style.width]="col.width" pSortableColumn="{{ col.sortable ? col.field : '' }}">
            {{ col.header }} <p-sortIcon field="{{ col.field }}" *ngIf="col.sortable" />
          </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          @for(col of columns; track col; let last = $last; let first = $first;) {
          <td [style.width]="col.width" class="vertical-align-top">
            <ng-container *ngTemplateOutlet="getTemplateRef(col.template); context: { rowData, col }"> </ng-container>
          </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-template #idColTemplate let-rowData="rowData" let-col="col">
  {{ rowData[col.field] }} <i class="pi pi-exclamation-triangle text-xl highlight" *ngIf="rowData.shouldHighlight"></i>
  <br />
  @if(rowData.is_priority) {
  <p-tag class="mr-1" severity="danger" value="Priority" />
  } @if(rowData.is_oversize) {
  <p-tag severity="danger" value="Oversize" />
  }
  <br />
  RefId: {{ rowData.ref_id }}
</ng-template>
<ng-template #commonTemplate let-rowData="rowData" let-col="col">
  {{ rowData[col.field] }}
</ng-template>
<ng-template #designItemTemplate let-rowData="rowData" let-col="col">
  @for(item of rowData[col.field]; track item.id; let last = $last){
  <div class="grid mb-2" [class]="{ 'border-bottom-1': !last, 'surface-border': !last }">
    <div class="col-4">
      <div class="grid">
        <div class="col-6">
          @if (item.mockup_front) {
          <app-image
            [src]="item.mockup_front"
            [text]="'mockup_front'"
            [context]="{ orderId: rowData.id, orderVariantId: item.id, retainUrl: true }"
            [width]="70"
            [height]="40"
          />
          }
        </div>
        <div class="col-6">
          @if (item.mockup_back) {
          <app-image
            [src]="item.mockup_back"
            [text]="'mockup_back'"
            [context]="{ orderId: rowData.id, orderVariantId: item.id, retainUrl: true }"
            [width]="70"
            [height]="40"
          />
          }
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="grid">
        <div class="col-12">
          <p-tag severity="warning" value="Qty: {{ item.quantity }}" class="mr-1" />
          <p-tag severity="info" value="Variant ID: {{ item.variant.id }}" />
          {{ item.product_name }}
          <br />
          {{ item.variant.style }} - {{ item.variant.color }} -
          {{ item.variant.size }}
          <div class="grid">
            @for(image of item.print_files; track image.id){
            <div class="col-3">
              <app-image
                class="cursor-pointer ml-2"
                [width]="30"
                [height]="10"
                [text]="image.name"
                [context]="{ orderId: rowData.id, orderVariantId: item.id }"
                [showQr]="true"
              />
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</ng-template>
<ng-template #trackingTemplate let-rowData="rowData" let-col="col">
  @if(!rowData[col.field] || rowData[col.field] === 'standard') {
  <p-tag severity="info" value="{{ rowData[col.field] }}" />
  } @else {
  <p-tag severity="danger" value="{{ rowData[col.field] }}" />
  }
  <i class="pi pi-info-circle warning cursor-pointer ml-1 text-blue-800 pRipple" (click)="op.toggle($event)"></i>
  <br />
  @if(rowData.shipping_label) {
  <p-tag class="cursor-pointer" severity="success" value="Print Label" (click)="onViewConvertLabel.emit(rowData)" />
  }
  {{ rowData.tracking_number }}
  <p-overlayPanel #op>
    <div class="flex flex-column gap-3 w-25rem">
      <div class="grid">
        <div class="col-12">
          <span class="font-medium text-900 block mb-2">Tracking Label Link</span>
          <p-inputGroup>
            <input pInputText [value]="rowData.shipping_label" readonly class="w-25rem" />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-external-link" (click)="externalLink.click()"></i>
              <a class="hidden" href="{{ rowData.shipping_label }}" target="_blank" #externalLink></a>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
        <div class="col-12">
          <span class="font-medium text-900 block mb-2">Tracking Number:</span>
          <p-inputGroup>
            <input pInputText value="{{ rowData.tracking_number }}" readonly class="w-full" #trackingNumber />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-copy" (click)="copyValue('Tracking Number', trackingNumber.value)"></i>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
        <div class="col-12">
          <span class="font-medium text-900 block mb-2">Fullname:</span>
          <p-inputGroup>
            <input pInputText value="{{ rowData.first_name }} {{ rowData.last_name }}" readonly class="w-full" #fullname />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-copy" (click)="copyValue('Fullname', fullname.value)"></i>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
        <div class="col-6">
          <span class="font-medium text-900 block mb-2">Email:</span>
          <p-inputGroup>
            <input pInputText value="{{ rowData.email }}" readonly class="w-full" #email />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-copy" (click)="copyValue('Email', email.value)"></i>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
        <div class="col-6">
          <span class="font-medium text-900 block mb-2">Phone:</span>
          <p-inputGroup>
            <input pInputText value="{{ rowData.phone }}" readonly class="w-full" #phone />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-copy" (click)="copyValue('Phone', phone.value)"></i>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
        <div class="col-12">
          <span class="font-medium text-900 block mb-2">Address:</span>
          <p-inputGroup>
            <input
              pInputText
              value="{{ rowData.street1 }}, {{ rowData.street2 }}, {{ rowData.city }}, {{ rowData.state }}, {{ rowData.zip_code }}, {{ rowData.country }}"
              readonly
              class="w-full"
              #address
            />
            <p-inputGroupAddon class="cursor-pointer pRipple">
              <i class="pi pi-copy" (click)="copyValue('Address', address.value)"></i>
            </p-inputGroupAddon>
          </p-inputGroup>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</ng-template>

<ng-template #dateTemplate let-rowData="rowData" let-col="col">
  {{ rowData[col.field] | date : "MMM d, y, h:mm:ss a" }}
</ng-template>

<ng-template #durationTemplate let-rowData="rowData" let-col="col">
  <!-- {{ rowData[col.field] - (rowData[col.field] > 0.5 ? 0.5 : 0) | number: '1.0-0' }} day(s) -->
  @if (rowData.status !== 'Cancelled') {
  {{ rowData[col.field] | number : "1.0-0" }} hour(s) }
</ng-template>

<ng-template #currencyTemplate let-rowData="rowData" let-col="col">
  {{ rowData[col.field] | currency : "USD" }}
  <i class="pi pi-info-circle warning cursor-pointer ml-1 text-blue-800 pRipple" (click)="constructPriceBreakdown(rowData); op.toggle($event)"></i>
  <p-overlayPanel #op>
    <div class="flex flex-column gap-3 w-180rem">
      <app-simple-pc-table [items]="orderItems" [cols]="orderCostTableCols"></app-simple-pc-table>
    </div>
  </p-overlayPanel>
</ng-template>

<ng-template #simpleCurrencyTemplate let-rowData="rowData" let-col="col">
  {{ rowData[col.field] | currency : "USD" }}
</ng-template>

<ng-template #actionTemplate let-rowData="rowData">
  <p-button
    icon="pi pi-pencil"
    pRipple
    [plain]="true"
    severity="success"
    (click)="edit.emit(rowData)"
    size="small"
    pTooltip="Update this order"
    tooltipPosition="bottom"
  />
  <p-button
    icon="pi pi-comments"
    pRipple
    [plain]="true"
    severity="info"
    (click)="openConversationDialog.emit(rowData)"
    size="small"
    pTooltip="Open conversation"
    tooltipPosition="bottom"
    [class]="{ 'border-500': !rowData.conversation }"
    [style]="{
      'margin-top': '4px',
      'background-color': rowData.conversation ? 'green' : 'gray'
    }"
  />
  <!-- <a [routerLink]="['/conversations/order', rowData.id]" class="p-button font-bold text-lg"
        [class]="{'surface-500': !rowData.conversation, 'border-500': !rowData.conversation}"
        style="padding: 0.75rem 0.95rem; margin-top: 4px;" pTooltip="Open Conversation">
        <i class="pi pi-comments"></i>
    </a> -->
</ng-template>

<ng-template #statusTemplate let-rowData="rowData" let-col="col">
  @if (fulfillStatus.options) {
  <p-inplace #inplace>
    <ng-template pTemplate="display">
      <span>{{ rowData[col.field] }}</span>
    </ng-template>
    <ng-template pTemplate="content">
      <p-dropdown
        [style]="{ width: '100%' }"
        [options]="fulfillStatus.options"
        #changedStatusValue
        optionLabel="{{ fulfillStatus.optionLabel }}"
        inputId="{{ fulfillStatus.key }}"
        optionValue="value"
        [ngModel]="rowData[col.field]"
        [loading]="!fulfillStatus.options.length"
        (onChange)="changeStatus(rowData.id, changedStatusValue.value); inplace.deactivate()"
      />
    </ng-template>
  </p-inplace>
  } @else {
  <span>{{ rowData[col.field] }}</span>
  }

  <br />
  <i class="pi pi-info-circle warning cursor-pointer ml-1 text-blue-800 pRipple" (click)="constructTimelines(rowData); op.toggle($event)"></i>
  <p-overlayPanel #op>
    <div class="flex flex-column gap-3 w-200rem">
      <app-timeline [timelines]="timelines"></app-timeline>
    </div>
  </p-overlayPanel>
</ng-template>

<ng-template #booleanTemplate let-rowData="rowData" let-col="col">
  @if(rowData[col.field]) {
  <i class="pi pi-check success text-green-800 pRipple"></i>
  } @else {
  <i class="pi pi-times warning text-red-800 pRipple"></i>
  }
</ng-template>
